<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        A sample of argon gas occupies a volume of
        <latex-number :number="Volume.toFixed(4)" unit="\text{m}^3" />
        at
        <stemble-latex content="$101.3\text{ kPa}$" />
        and
        <stemble-latex content="$25.0^\circ\text{C.}$" />
        What volume would the same sample of gas occupy if the pressure was increased to
        <number-value :value="Pressure" unit="\text{kPa}" />
        at constant temperature.
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\text{Volume:}$"
        append-text="$\mathrm{m}^3$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '../displayers/LatexNumber';

export default {
  name: 'Question65',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    Pressure() {
      return this.taskState.getValueBySymbol('Pressure').content;
    },
    Volume() {
      return this.taskState.getValueBySymbol('Volume').content.toFloat();
    },
  },
};
</script>
